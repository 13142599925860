<script>
import CategoriesServiceApi from "@/service/api/categories";
import ProductServiceApi from "@/service/api/product";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import tags from "@/service/prod-tags";
import VueSlider from "vue-slider-component";
import Spinner from "@/components/spinner";
import "vue-slider-component/theme/antd.css";
import BrandServiceApi from "@/service/api/brands";
import "vue-search-select/dist/VueSearchSelect.css";
import { ModelSelect } from "vue-search-select";
export default {
  page: {
    title: "Product",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    VueSlider,
    Spinner,
    ModelSelect,
  },
  data() {
    return {
      title: "Products",
      items: [
        {
          text: "Ecommerce",
        },
        {
          text: "Products",
          active: true,
        },
      ],
      tagsSelect: [],
      categories: null,
      secondary_cateogry: null,
      products: null,
      perPage: 15,
      tags: null,
      currentPage: 1,
      rows: null,
      total: null,
      listProductTable: false,
      pageOptions: [15, 25, 50, 100],
      showAddform: false,
      filter: null,
      filterOn: [],
      value: null,
      myFiles: [],
      mainCatSelect: null,
      primaryCatSelect: null,
      primaryCategory: null,
      secondaryCategory: null,

      brandData: null,

      loading: false,

      searchTerm: null,
      formSubmitted: false,

      only_size: false,
      is_collectibles: false,
      fields: [
        { key: "id", sortable: true, label: "ID" },
        { key: "name", sortable: true, label: this.$t("brands.table.name") },
        { key: "condition", sortable: true },
        { key: "retail_price", sortable: true },
        { key: "release_date", sortable: true },
        { key: "lowest_ask", sortable: true },
        { key: "highest_bid", sortable: true },
        { key: "release_date", sortable: true },
        {
          key: "status",
          sortable: true,
          label: this.$t("brands.table.status"),
        },
      ],
      genderOpt: {
        M: "Men",
        F: "Women",
        C: "Children",
        P: " Preschool",
        I: "Toddler",
        T: "Infant",
      },
      gradeCompany: {
        PSA: "PSA",
        BGS: "BGS",
        SGC: "SGC",
      },
      productForm: {
        name: null,
        desc: null,
        color: null,
        SKU: null,
        condition: null,
        retail_price: null,
        release_date: null,
        image_path: null,
        gender: null,
        grade: null,
        secondary_id: null,
        popular: false,
      },
      params: {
        category_id: null,
        primary_id: null,
        secondary_id: null,
        paginate: 15,
        page: 1,
        price_min: null,
        price_max: null,
        popular: null,
        name: null,
      },
      priceRange: [0, 10000],
      isPopular: false,

      brandSelect: {
        value: null,
        text: null,
      },
    };
  },
  computed: {
    brandDataList() {
      return this.brandData
        ? this.brandData.map((item) => {
            return { value: item.id, text: item.name_en };
          })
        : null;
    },
    imageUrl() {
      if (
        this.productForm.image_path &&
        this.productForm.image_path.files[0] &&
        this.productForm.image_path.files[0].file
      )
        return URL.createObjectURL(this.productForm.image_path.files[0].file);
      else {
        return null;
      }
    },
    totalPages() {
      return parseInt(this.rows / this.perPage);
    },

    mainCategory() {
      return this.categories.map((item) => {
        return {
          label: `${item.name_en}-${item.name_ar}`,
          value: item.id,
        };
      });
    },
    primaryCategoryOpt() {
      if (this.primaryCategory) {
        return this.primaryCategory.map((item) => {
          return {
            label: `${item.name}`,
            value: item.id,
          };
        });
      } else {
        return [];
      }
    },
  },
  watch: {
    mainCatSelect(val) {
      this.primaryCategory = this.filterPrimaryCategory(val);
      this.secondaryCategory = [];
      switch (val) {
        case "1":
          this.tags = tags.Sneakers;
          this.is_collectibles = false;
          this.only_size = true;
          this.tagsSelect = [];
          break;
        case "2":
          this.tags = tags.Streetwear;
          this.is_collectibles = false;
          this.only_size = true;
          this.tagsSelect = [];
          break;
        case "3":
          this.tags = tags.Collectibles;
          this.is_collectibles = false;
          this.tagsSelect = [];
          this.is_collectibles = true;
          break;
        default:
          this.tags = null;
          this.is_collectibles = false;
          this.tagsSelect = [];
          break;
      }
    },
    primaryCatSelect(val) {
      this.secondaryCategory = this.filterSecondaryCategory(val);
    },
    isPopular(val) {
      this.params.page = 1;
      if (val) this.params.popular = "1";
      else this.params.popular = null;
      this.loadProducts();
    },
  },
  methods: {
    loadProducts() {
      this.loading = true;
      this.showAddform = false;
      ProductServiceApi.getAllProducts(this.params)
        .then((response) => {
          this.products = response.data.products.data;
          this.rows = response.data.products.total;
          this.perPage = response.data.products.per_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    productSelected(val) {
      const productId = val.pop().id;
      this.openProductDetails(productId);
    },
    productAddMessage(data, variant = "success") {
      this.$bvToast.toast(`Product ${data.name} is added !`, {
        title: `Product added`,
        variant: variant,
        solid: true,
      });
    },
    productAddErrorMessage(data, variant = "danger") {
      this.$bvToast.toast(`Product is not added!, failed with error: ${data}`, {
        title: `Opration Failed`,
        variant: variant,
        solid: true,
      });
    },

    submitProductHandler(data) {
      if (this.brandSelect.value) {
        var form_data = new FormData();
        form_data.append("color", data.color);
        form_data.append("condition", data.condition);
        form_data.append("desc", data.desc);
        form_data.append("gender", data.gender);
        form_data.append("brand_id", this.brandSelect.value);
        form_data.append("popular", data.popular ? "1" : "0");
        form_data.append(
          "image_path",
          this.productForm.image_path.files[0].file
        );
        form_data.append("name", data.name);
        form_data.append("name_ar", data.name_ar);
        form_data.append("release_date", data.release_date);
        form_data.append("retail_price", data.retail_price);
        form_data.append("secondary_id", this.productForm.secondary_id);
        form_data.append("SKU", data.SKU);

        form_data.append("action", "add");

        if (!this.only_size) {
          form_data.append("only_size", true);
        } else {
          if (this.is_collectibles) {
            if (data.grade) {
              form_data.append("grade", data.grade);
              this.tagsSelect.forEach((item) => {
                form_data.append("sizelist[]", item);
              });
            }
          } else {
            this.tagsSelect.forEach((item) => {
              form_data.append("sizelist[]", item);
            });
          }
        }

        ProductServiceApi.productAction(form_data).then((response) => {
          if (response.data.success) {
            this.productAddMessage(response.data.product[0]);
            this.resetForm();
            this.$formulate.resetValidation("productAddForm");
            this.$formulate.reset("productCategoryForm");
          } else {
            this.productAddErrorMessage(response.data.error);
          }
        });
      }
    },
    resetForm() {
      this.productForm.name = null;
      this.productForm.name_ar = null;
      this.productForm.desc = null;
      this.productForm.color = null;
      this.productForm.SKU = null;
      this.productForm.condition = null;
      this.productForm.retail_price = null;
      this.productForm.release_date = null;
      this.productForm.gender = null;
      this.productForm.grade = null;
      this.productForm.popular = false;
      this.productForm.image_path = false;
    },

    filterPrimaryCategory(id = null) {
      if (id) {
        let primeData = this.categories.filter(
          (item) => item.id === parseInt(id)
        );
        if (primeData.length > 0) {
          return primeData[0].primary;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    openProductDetails(id = null) {
      if (id) {
        this.$router.push({ name: "product detail", params: { id: id } });
      } else {
        return;
      }
    },
    filterSecondaryCategory(id = null) {
      if (id && this.primaryCategory) {
        let secondData = this.primaryCategory.filter(
          (item) => item.id === parseInt(id)
        );
        if (secondData.length > 0) {
          return secondData[0].secondary.map((item) => {
            return {
              label: `${item.name}`,
              value: item.id,
            };
          });
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    filterCategory(ele, data) {
      if (ele.path[0].ariaExpanded || data.exept) {
        this.resetRageSlider();
        switch (data.type) {
          case "main":
            this.params.primary_id = null;
            this.params.secondary_id = null;
            if (this.params.category_id !== data.id) {
              this.params.category_id = data.id;
              this.params.page = 1;
              this.loadProducts();
            }
            break;
          case "primary":
            this.params.category_id = null;
            this.secondaryCatFilterId = null;
            if (this.params.primary_id !== data.id) {
              this.params.primary_id = data.id;
              this.params.page = 1;
              this.loadProducts();
            }
            break;
          case "secondary":
            this.params.category_id = null;
            this.params.primary_id = null;
            if (this.params.secondary_id !== data.id) {
              this.params.secondary_id = data.id;
              this.params.page = 1;
              this.loadProducts();
            }
            break;
          default:
            break;
        }
        this.currentPage = 1;
      }
    },
    resetRageSlider() {
      this.params.price_min = null;
      this.params.price_max = null;
      this.priceRange = [0, 10000];
    },
    filterAll() {
      this.params.category_id = null;
      this.params.primary_id = null;
      this.params.secondary_id = null;
      this.params.page = 1;
      this.currentPage = 1;
      this.params.name = null;
      this.resetRageSlider();
      this.loadProducts();
    },
    selectedRange() {
      this.params.price_min = this.priceRange[0];
      this.params.price_max = this.priceRange[1];
      this.params.page = 1;
      this.currentPage = 1;
      this.loadProducts();
    },
    initPagination(val) {
      this.params.page = val;
      this.loadProducts();
    },
    searchByName() {
      this.params.page = 1;
      if (this.searchTerm && this.searchTerm.length) {
        this.params.name = this.searchTerm;
      } else {
        this.params.name = null;
      }

      this.loadProducts();
    },
    entriesChange(val) {
      this.params.paginate = val;
      this.loadProducts();
    },
    trimText(text) {
      return text.length > 48 ? text.slice(0, 48) + "..." : text;
    },
    loadAllBrand() {
      BrandServiceApi.getAllBrands().then((response) => {
        this.brandData = response.data;
      });
    },
  },
  mounted() {
    CategoriesServiceApi.getAllCategories().then((response) => {
      this.categories = response.data;
    });
    this.loadProducts();
    this.loadAllBrand();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="spinner-prod">
      <Spinner :show="loading" />
    </div>
    <div class="row">
      <div class="col-xl-3 col-lg-4">
        <div class="product-add-st">
          <b-button variant="light" @click="() => (this.showAddform = true)">
            <i class="ri-add-box-fill align-middle"></i> Add Product</b-button
          >
        </div>
        <div class="card">
          <div class="card-body category-filter">
            <h5 class="font-size-14 mb-3">Categories</h5>
            <a class="categories-group-list" @click="filterAll()">
              All
              <i class="mdi mdi-minus float-right"></i>
            </a>
            <div
              id="accordion"
              class="custom-accordion categories-accordion mb-3"
            >
              <div
                class="categories-group-card"
                v-for="category in categories"
                :key="category.id"
              >
                <a
                  v-b-toggle="`maincategory${category.id}`"
                  class="categories-group-list"
                  @click="
                    filterCategory($event, {
                      type: 'main',
                      id: category.id,
                      exept: false,
                    })
                  "
                >
                  {{ category.name_en }}
                  <i class="mdi mdi-minus float-right accor-plus-icon"></i>
                </a>

                <b-collapse
                  :id="'maincategory' + category.id"
                  data-parent="#accordion"
                  accordion="my-accordion"
                >
                  <div
                    class="list-unstyled mb-0"
                    v-for="primary in category.primary"
                    :key="primary.id"
                  >
                    <div
                      id="secondary_accordion"
                      class="custom-accordion categories-accordion"
                    >
                      <div style="padding-left:18px;">
                        <a
                          v-b-toggle="`primarycategory${primary.id}`"
                          class="categories-group-list"
                          @click="
                            filterCategory($event, {
                              type: 'primary',
                              id: primary.id,
                              exept: false,
                            })
                          "
                        >
                          {{ primary.name }}
                          <i
                            class="mdi mdi-minus float-right accor-plus-icon"
                          ></i>
                        </a>

                        <b-collapse
                          :id="'primarycategory' + primary.id"
                          data-parent="#secondary_accordion"
                          accordion="secondary_accordion"
                        >
                          <div>
                            <ul
                              class="list-unstyled categories-list mb-0"
                              v-for="secondary in primary.secondary"
                              :key="secondary.id"
                              style="margin:5px;padding:0;"
                            >
                              <li>
                                <a
                                  @click="
                                    filterCategory($event, {
                                      type: 'secondary',
                                      id: secondary.id,
                                      exept: true,
                                    })
                                  "
                                >
                                  {{ secondary.name }}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </b-collapse>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>

        <div class="m-2 mb-4">
          Only Popular
          <b-form-checkbox
            v-model="isPopular"
            class="primary"
            name="check-button"
            switch
            style="display:inline"
          >
          </b-form-checkbox>
        </div>
        <div class="m-2 mb-4">
          Lowest Ask Range
          <vue-slider
            v-model="priceRange"
            :min="0"
            :max="10000"
            :interval="100"
            v-on:drag-end="selectedRange"
          />
        </div>

        <div id="change-product-view">
          <FormulateInput
            v-model="listProductTable"
            type="checkbox"
            @click="() => (this.showAddform = false)"
            label="view as table"
          />
        </div>
      </div>
      <div class="col-xl-9  col-lg-8" id="productAddFormId">
        <div v-if="showAddform">
          <div class="card">
            <div class="card-body" id="change-product-view">
              <FormulateForm
                name="productAddForm"
                @submit="submitProductHandler"
                v-model="productForm"
              >
                <div style="margin: 10px 30px">
                  <div class="row">
                    <div class="col-md-6">
                      <FormulateInput
                        type="text"
                        name="name"
                        label="Name"
                        placeholder="Product name here"
                        validation="required"
                      />
                      <FormulateInput
                        type="text"
                        name="name_ar"
                        label="Name in (Arabic)"
                        placeholder="Product name in arabic"
                      />
                      <FormulateInput
                        type="image"
                        v-model="productForm.image_path"
                        label="Select an image to upload"
                        help="Select a png, jpg or gif to upload."
                        validation="mime:image/jpeg,image/png,image/gif|required"
                      />
                    </div>
                    <div class="col-md-6">
                      <div class="product-preview-form">
                        <div class="product-box" style="height:262px;">
                          <div class="product-img mb-1">
                            <img
                              :src="imageUrl"
                              v-if="imageUrl"
                              onerror="javascript:this.src=''"
                              alt="Please upload image"
                              class="img-fluid mx-auto d-block"
                              style="height:148px;width:158px;"
                            />
                            <img
                              v-else
                              src="@/assets/images/noimage.png"
                              alt="Please upload image"
                              class="img-fluid mx-auto d-block"
                              style="height:148px;width:158px;"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <FormulateInput
                        type="textarea"
                        name="desc"
                        label="Description"
                        placeholder="Description here"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <FormulateInput
                        type="number"
                        name="retail_price"
                        label="Retail Price"
                        validation="required"
                        placeholder="Retail price here"
                        min="0"
                      />
                    </div>
                    <div class="col-md-6">
                      <FormulateInput
                        type="text"
                        name="SKU"
                        label="SKU"
                        validation="required"
                        placeholder="Product SKU here"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <FormulateInput
                        type="date"
                        name="release_date"
                        label=" Release Date"
                        validation="required"
                        placeholder="Release date"
                      />
                    </div>
                    <div class="col-md-6">
                      <FormulateInput
                        name="gender"
                        :options="genderOpt"
                        type="select"
                        validation="required"
                        placeholder="Gender"
                        label="Gender"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                       <FormulateInput
                          :options="{'New': 'New','Graded': 'Graded'}"
                          type="radio"
                          name="condition"
                          label="Condition"
                          validation="required"
                        />
                    </div>
                    <div class="col-md-6">
                      <FormulateInput
                        type="text"
                        name="color"
                        label="Color"
                        validation="required"
                        placeholder="Product color"
                      />
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-6">
                      <FormulateInput
                        type="checkbox"
                        name="popular"
                        label="Is Popular"
                        placeholder="Is Pouplar?"
                      />
                    </div>
                  </div>
                  <div class="mt-5">
                    <h6>
                      Category Details
                    </h6>
                  </div>
                  <hr />

                  <FormulateForm name="productCategoryForm">
                    <div class="row">
                      <div class="col-md-6">
                        <FormulateInput
                          :options="mainCategory"
                          v-model="mainCatSelect"
                          type="select"
                          placeholder="Select main category"
                          label="Category"
                        />
                      </div>
                      <div class="col-md-6 custom-search">
                        <div
                          style="font-weight:600;margin-bottom:6px;margin-top:10px;"
                        >
                          Brands
                        </div>
                        <model-select
                          :options="brandDataList"
                          v-model="brandSelect"
                          placeholder="Select a Brand"
                        >
                        </model-select>
                        <div
                          style="font-size:12px;color:#960505;font-weight:100"
                          v-if="!brandSelect.value && formSubmitted"
                        >
                          Brand is required!
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <FormulateInput
                          v-model="primaryCatSelect"
                          :options="primaryCategoryOpt"
                          type="select"
                          placeholder="Select primary category"
                          label="Primary category"
                        />
                      </div>
                      <div class="col-md-6">
                        <FormulateInput
                          v-model="productForm.secondary_id"
                          :options="secondaryCategory"
                          type="select"
                          placeholder="Select secondary category"
                          label="Secondary category"
                          validation="required"
                        />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6" v-if="is_collectibles">
                        <FormulateInput
                          name="grade"
                          :options="gradeCompany"
                          type="select"
                          placeholder="Choose a Company Grade"
                          label="Choose a Company Grade"
                        />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <div style="margin-top:10px;">
                          <div>
                            <span v-if="is_collectibles">
                              Grade
                            </span>
                            <span v-else>Sizes</span>
                          </div>
                          <div style="margin-top:10px;">
                            <b-form-checkbox v-model="only_size" switch>
                            </b-form-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="tags && only_size">
                      <div class="col-12 product-tag-wr-st">
                        <div class="product-tag-st">
                          <div v-if="!is_collectibles" class="pb-2">
                            Please select available sizes
                          </div>
                          <div v-else class="pb-2">
                            Please choose a grade
                          </div>
                          <b-form-checkbox-group
                            v-model="tagsSelect"
                            :options="tags"
                          ></b-form-checkbox-group>
                        </div>
                      </div>
                    </div>
                  </FormulateForm>
                </div>

                <div class="m-4" style="padding-bottom:50px;">
                  <div class="mt-4 text-center m-2">
                    <button
                      class="btn btn-primary w-md waves-effect waves-light float-right"
                      type="submit"
                      @click="() => (this.formSubmitted = true)"
                    >
                      Add
                    </button>

                    <button
                      class="btn btn-primary w-md waves-effect waves-light float-left"
                      type="button"
                      @click="
                        () => {
                          this.showAddform = false;
                          this.brandSelect.value = null;
                          this.brandSelecttext = null;
                          this.formSubmitted = false;
                          this.loadProducts();
                        }
                      "
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </FormulateForm>
            </div>
          </div>
        </div>

        <div class="card" v-else>
          <div class="card-body">
            <div>
              <div class="row mt-4" style="padding-top:10px;">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select
                        v-on:change="entriesChange"
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                      ></b-form-select
                      >&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-right"
                  >
                    <span class="d-inline-flex align-items-center">
                      <b-form-input
                        v-model="searchTerm"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ml-2"
                      ></b-form-input>
                      <button class="btn-primary ml-1" @click="searchByName">
                        <i class="ri-search-line"></i>
                      </button>
                    </span>
                  </div>
                </div>
                <!-- End search -->
              </div>

              <div
                class="row no-gutters"
                style="padding-top:10px;"
                v-if="!listProductTable"
              >
                <div
                  class="col-xl-4 col-sm-6"
                  v-for="product in products"
                  :key="product.id"
                >
                  <div
                    class="product-box"
                    style="height:300px;"
                    @click="openProductDetails(product.id)"
                  >
                    <div class="product-img">
                      <img
                        :src="product.image"
                        alt
                        class="img-fluid mx-auto d-block"
                        style="height:148px"
                      />
                    </div>

                    <div class="text-center">
                      <p class="font-size-12 mb-1">
                        For {{ product.genderType }}
                      </p>
                      <p class="font-size-15">
                        <span class="text-dark">{{
                          trimText(product.name)
                        }}</span>
                      </p>

                      <h6 class="mt-3 mb-0" v-if="product.lowest_ask">
                        <span style="color:gray">
                          Lowest Ask
                        </span>
                        <span class="text-muted mr-1"> </span>AED
                        {{ product.lowest_ask }}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              <div style="padding-top:10px;" v-else>
                <div class="table-responsive mb-0" v-if="products">
                  <b-table
                    :items="products"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @row-selected="productSelected"
                    selectable
                  >
                  </b-table>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-sm-6">
                  <div>
                    <p class="mb-sm-0 mt-2">
                      Page
                      <span class="font-weight-bold">{{ currentPage }}</span> Of
                      <span class="font-weight-bold">{{ totalPages + 1 }}</span>
                    </p>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="float-sm-right">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          v-on:change="initPagination"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<style>
#change-product-view .formulate-input-element--checkbox {
  margin-top: 8px;
}
.product-add-st {
  margin: 10px auto;
}
.product-add-st button {
  width: 100%;
  color: brown;
}
.product-preview-form {
  height: 100%;
  overflow: hidden;
}
.product-tag-st {
  margin: 30px 0px;
}

.product-tag-st .custom-control-inline {
  width: 60px;
}
.product-box {
  cursor: pointer;
}

.category-filter a:hover {
  cursor: pointer;
}
.custom-search .dropdown {
  background-color: #f1f1f1 !important;
  border: none !important;
}
#productAddFormId .formulate-input-group{
  display: flex;
    justify-content: space-between;
    width: 174px;
}
</style>
